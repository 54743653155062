<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>
              Generación de reporte
              <span class="d-block text-muted pt-2 font-size-sm"
                >Generación de reporte parametrizado sobre excelencia
                académica</span
              >
            </h3>
            <p class="ma-0 pa-0 text-body-1">
              <strong
                >Antes de poder generar el reporte, es nesario que seleccione
                almenos 4 parametros.</strong
              >
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - El <strong>año electivo</strong> de los registros a mostrar.
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - La lista de <strong>grupos académicos</strong> se cargará cuando
              haya elegido un grado.
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - La lista de
              <strong>grupos de especialidad</strong> se cargará cuando haya
              seleccionado un grado mayor a 9°.
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - No se puede seleccionar un grupo académico y un grupo de
              especialidad al mismo tiempo.
            </p>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->

      <div class="card-body px-1 px-sm-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:grade combo -->
                        <v-select
                          label="Año electivo seleccionado"
                          filled
                          rounded
                          :loading="areYearsLoading"
                          :disabled="areYearsLoading"
                          hide-details
                          :items="availableYears"
                          item-text="year"
                          item-value="year"
                          v-model="students.selectedYear"
                          @click="resetFilters()"
                        >
                          <!-- begin::selected grade item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.year }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected grade item -->
                        </v-select>
                        <!-- end:grade combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:grade combo -->
                        <v-select
                          label="Grado"
                          filled
                          rounded
                          :loading="isSectionGroupLoading"
                          hide-details
                          :items="grades"
                          item-text="name"
                          item-value="id"
                          v-model="students.filters.grade_id"
                          @click="resetFilters()"
                          @change="loadAcademicGroups(), loadTechnicalGroups()"
                        >
                          <!-- begin::selected grade
                           item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected grade item -->
                        </v-select>
                        <!-- end:grade combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:: academicGroup combo -->
                        <v-select
                          filled
                          rounded
                          hide-details
                          clearable
                          item-text="grade_section_group_formatted"
                          item-value="id"
                          v-model="students.filters.grade_section_group_id"
                          @change="loadStages"
                          :loading="isSectionGroupLoading"
                          :disabled="
                            !students.filters.grade_id ||
                            students.filters.grade_id > 3 ||
                            !!students.filters.grade_speciality_group_id ||
                            isSectionGroupLoading
                          "
                          :items="academicGroups"
                          :label="
                            isSectionGroupLoading
                              ? 'Cargando grupos académicos...'
                              : 'Grupo académico'
                          "
                        >
                          <!-- begin::selected academicGroup item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{
                                item.grade_section_group_formatted
                              }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected academicGroup item -->
                        </v-select>
                        <!-- end:: academicGroup combo -->
                      </div>
                    </v-col>
                    <!-- end::select section/group filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <!-- begin:: technicalGroup combo -->
                      <div class="d-flex align-items-center">
                        <v-select
                          :label="
                            isSectionGroupLoading
                              ? 'Cargando grupos técnicos...'
                              : 'Grupo técnico'
                          "
                          :loading="isSectionGroupLoading"
                          :disabled="
                            !students.filters.grade_id ||
                            students.filters.grade_id < 4 ||
                            !!students.filters.grade_section_group_id ||
                            isSectionGroupLoading
                          "
                          filled
                          rounded
                          hide-details
                          clearable
                          :items="technicalGroups"
                          item-text="grade_speciality_group_formatted"
                          item-value="id"
                          v-model="students.filters.grade_speciality_group_id"
                          @change="loadStages"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{
                                item.grade_speciality_group_formatted
                              }}</span>
                            </v-chip>
                          </template>
                        </v-select>
                      </div>
                      <!-- end:: technicalGroup combo -->
                    </v-col>
                    <!-- end::select section/group filter-->

                    <!-- begin::select stage filter -->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin::stage combo -->

                        <v-select
                          :label="
                            isSectionGroupLoading
                              ? 'Cargando etapas...'
                              : 'Etapa'
                          "
                          :loading="isSectionGroupLoading"
                          :disabled="!canSelectStage"
                          filled
                          rounded
                          hide-details
                          clearable
                          :items="stages"
                          item-text="stage.name"
                          item-value="stage_id"
                          v-model="students.filters.stage_id"
                        >
                          <!-- begin::selected stage item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.stage.name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected stage item -->
                        </v-select>
                        <!-- end::stage combo -->
                      </div>
                    </v-col>
                    <!-- end::select stage filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->

              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with students scores col-container-->
                  <v-col cols="12" sm="8" md="8" lg="6" xl="4">
                    <!-- begin::excel with students scores-->
                    <v-card outlined :disabled="!canGenerateReport">
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">estudiantes</div>
                          <v-list-item-title class="text-h5 mb-1">
                            Excelencia académica
                          </v-list-item-title>
                          <v-list-item-subtitle tag="p"
                            >Estudiantes agrupados por
                            <strong
                              >grupo
                              {{
                                students.filters.stage_id == 8
                                  ? "técnico"
                                  : "académico"
                              }}</strong
                            >
                            en una etapa.</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded"
                          tile
                          icon
                          size="80"
                          color="success"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-microsoft-excel</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          @click="getAcademicExcellenceReportUrl()"
                        >
                          Generar reporte
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                  </v-col>
                  <!-- end::excel with students scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import settingsRepository from "@/repositories/settingsRepository";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import stageRepository from "@/repositories/stageRepository";
import gradeRepository from "@/repositories/gradeRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";

export default {
  name: "AcademicExcellenceReport",
  title: "Reporte de excelencia académica | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes", route: "reports" },
      {
        title: "Reporte de excelencia académica",
        route: "academic_excellence",
      },
    ]);
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Reporte de excelencia académica"
    );

    this.loadGrades();
    this.loadYears();
  },

  // TODO: Delete useless constants
  data() {
    return {
      students: {
        data: [],
        filters: {},
        isLoading: false,
        selectedYear: 2023,
      },
      technicalGroupSpeciality: {},
      isSectionGroupLoading: false,
      areStagesloading: false,
      areYearsLoading: false,
      //🚩 use endpoint for years whenever posible.
      availableYears: [],
      technicalExcel: [],
      academicExcel: [],
      stages: [],
      grades: [],
      academicGroups: [],
      technicalGroups: [],
      TERCER_CICLO_ID: 1,
      BACHILLERATO_ID: 2,
      allPeriods: {
        stage_id: 20,
        stage: {
          name: "TODOS LOS PERÍODOS",
        },
      },

      allTrimesters: {
        stage_id: 21,
        stage: {
          name: "TODOS LOS TRIMESTRES",
        },
      },
      customGradeSpecialityGroupsByGradeId: {
        1: [
          {
            id: 1,
            grade_speciality_group_formatted: "Todo séptimo grado",
          },
        ],
        2: [
          {
            id: 2,
            grade_speciality_group_formatted: "Todo octavo grado",
          },
        ],
        3: [
          {
            id: 3,
            grade_speciality_group_formatted: "Todo noveno grado",
          },
        ],
      },
    };
  },
  methods: {
    loadYears() {
      this.areYearsLoading = true;
      settingsRepository
        .getAllYears()
        .then(({ data }) => {
          this.availableYears = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areYearsLoading = false;
        });
    },

    //Fetching all grade academic levels
    loadGrades() {
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },

    //Fetching academic groups depending on the id of the grade that's been selected
    loadAcademicGroups() {
      this.isSectionGroupLoading = true;
      academicGroupRepository
        .getAcademicGroupsByGrade(this.students.filters.grade_id)
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isSectionGroupLoading = false;
        });
    },

    //Fetching technical groups depending on the academicGroup id that's been selected
    loadTechnicalGroups() {
      if (this.academicLevelId === this.TERCER_CICLO_ID) {
        this.technicalGroups =
          this.customGradeSpecialityGroupsByGradeId[
            this.students.filters.grade_id
          ];
        return;
      }
      technicalGroupRepository
        .getTechnicalGroupsByGrade(this.students.filters.grade_id)
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },

    //Fetching all yearly stages
    loadStages() {
      this.areStagesloading = true;

      const gradeSpecialityGroup = this.technicalGroups.find(
        (item) => item.id === this.students.filters.grade_speciality_group_id
      );

      const speciality_id = gradeSpecialityGroup
        ? gradeSpecialityGroup.speciality_id
        : 8;

      let selectedGrade = this.grades.find((grade) => {
        return grade.id === this.students.filters.grade_id;
      });

      stageRepository
        .stageByAcademicLevel(this.students.filters.grade_id, speciality_id)
        .then(({ data }) => {
          this.stages = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          if (selectedGrade.academic_level_id == 1) {
            this.stages.push(this.allTrimesters);
          } else {
            this.stages.push(this.allPeriods);
          }
          this.areStagesloading = false;
        });
    },

    async getAcademicExcellenceReportUrl() {
      //if there isnt stage_id
      if (!"stage_id" in this.students.filters) {
        return;
      }

      let url;
      const year = await this.encryptCode(this.students.selectedYear);

      if (this.students.filters.grade_id > 3) {
        url =
          await `https://reportsge.ricaldone.edu.sv/public/api/scores/academic-excellences/excel/grade-speciality-group/${this.students.filters.grade_speciality_group_id}/stage/${this.students.filters.stage_id}/year/${year}`;
        window.open(url, "_blank");
        return;
      } else {
        url =
          await `https://reportsge.ricaldone.edu.sv/public/api/scores/academic-excellences/excel/grade-section-group/${this.students.filters.grade_section_group_id}/stage/${this.students.filters.stage_id}/year/${year}`;

        window.open(url, "_blank");
        return;
      }
    },

    async encryptCode(code) {
      try {
        // Se hace la petición para encriptar el id del grupo académico
        const { data: codeEncrypted } = await ApiService.get(
          `${this.reportsApiUrl}/version/u/${code}`
        );
        return codeEncrypted;
      } catch (error) {
        // Se settea la variable true para ocultar el botón
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los datos desde el servidor",
        });
        return;
      }
    },

    resetFilters() {
      this.students.filters = {};
    },

    resetStages() {
      this.stages = [];
      this.students.filters.stage_id = null;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    reportsApiUrl() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },

    academicLevelId() {
      if ("grade_id" in this.students.filters) {
        const academicLevel = this.grades.find(
          (item) => item.id == this.students.filters.grade_id
        );
        return academicLevel.academic_level.id;
      }
    },

    modularStageHasBeenSelected() {
      return this.students.filters.stage_id == 8;
    },

    filteredData() {
      return this.filterData(this.students.filters, this.students.data);
    },

    canSelectStage() {
      const gradeHasBeenSelected = !!this.students.filters.grade_id;
      const technicalOrAcademicGroupHasBeenSelected = !!(
        this.students.filters.grade_section_group_id ||
        this.students.filters.grade_speciality_group_id
      );
      return gradeHasBeenSelected && technicalOrAcademicGroupHasBeenSelected;
    },

    canGenerateReport() {
      const stageHasBeenSelected = !!this.students.filters.stage_id;
      return stageHasBeenSelected && this.canSelectStage;
    },
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
